@import url("https://fonts.googleapis.com/css2?family=Archivo&family=Space+Grotesk&family=Archivo+Black:wght@200;400;500;600;700&display=swap");

@media (max-width: 900px) {
  .intro_subtitle__cj7I3 h1 {
    font-size: 19px;
    line-height: 1.2;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .intro_buttonContainer__oLuSU {
    flex-direction: row;
    gap: 10px;
  }

  .intro_button__0j2Am {
    padding: 8px 16px; /* Adjust padding to fit smaller screens */
    font-size: 12px; /* Adjust font size to ensure it's readable but not too large */
    margin: 0; /* Adjust or remove margin as needed */
  }

  .intro_line__SKfTo {
    display: none; /* Hide the line on smaller screens if it disrupts the layout */
  }
}

@media (max-width: 768px) {
  .intro_content__dr1fu {
    padding: 20px; /* Add padding to ensure content doesn't touch the edges */
  }
}

@media (max-width: 900px) {
  .intro_button__0j2Am {
    text-decoration: underline;
    text-underline-offset: 10px;
  }
}

.intro_videoBackground__B6P5F {
  position: relative;
  width: 100vw;
  height: 100vh;
  flex: auto;
  overflow: hidden;
  -o-object-fit: cover;
     object-fit: cover;
}

.intro_video__vYtch {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  filter: blur(9px);
  transform: scale(1.5);
}

.intro_content__dr1fu {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.intro_header___1eUP h2 {
  font-size: 1.25rem;
  margin-bottom: 0.5rem; /* Space below the small text */
  color: white;
}

.intro_leftHeader__a_Ax6 {
  position: absolute;
  top: 5%;
  left: 2%;
  text-align: right;
  color: white;
  font-size: 25px;
  box-sizing: border-box;
}

.intro_leftHeader__a_Ax6 > div {
  display: block;
}

.intro_subtitle__cj7I3 h1 {
  font-size: 64px;
  font-weight: bold;
  margin-bottom: 1rem;
  text-align: center;
  color: white;
}

.intro_title__RXS1h {
  font-size: 64px;
  margin-bottom: 1rem;
  color: white;
}

.intro_buttonContainer__oLuSU {
  display: flex;
  align-items: center;
  justify-content: center;
}

.intro_button__0j2Am {
  background-color: transparent; /* No background */
  border: none; /* No border */
  color: white; /* White text color */
  font-size: 32px; /* Adjust font size as needed */
  text-transform: uppercase; /* UPPERCASE text */
  padding: 10px 20px; /* Padding around the text */
  margin: 0 20px; /* Space between buttons */
  position: relative; /* Positioning context for pseudo-elements */
  overflow: hidden; /* Ensures the pseudo-element is clipped to the button's size */
  cursor: pointer; /* Cursor indicates a clickable button */
}

.intro_button__0j2Am::after {
  content: ""; /* Pseudo-element content */
  display: block; /* Block display to allow width and height to be set */
  width: 0; /* Start with no width */
  height: 2px; /* Height of the underline */
  background-color: white; /* Color of the underline */
  position: absolute; /* Positioned absolutely within the button */
  bottom: 0; /* Align to the bottom of the button */
  left: 0; /* Start from the left */
  transition: width 0.3s ease; /* Animate the width change */
}

@media (max-width: 900px) {
  .intro_button__0j2Am::after {
    display: none;
  }
}

@media (min-width: 901px) {
  .intro_button__0j2Am:hover::after {
    width: 100%;
  }
}

.intro_line__SKfTo {
  height: 2px;
  width: 175px;
  background-color: white;
  margin: 0 20px;
}

@keyframes intro_typing__CUHUr {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@keyframes intro_typing2__uqxqm {
  0% {
    width: 0;
  }
  from {
    opacity: 0;
  }
  1% {
    opacity: 1;
  }
  to {
    opacity: 1;
    width: 100%;
  }
  100% {
    opacity: 1;
  }
}

@keyframes intro_typing3__T1Etn {
  0% {
    width: 0;
  }
  from {
    opacity: 0;
  }
  1% {
    opacity: 1;
  }
  to {
    opacity: 1;
    width: 100%;
  }
  100% {
    opacity: 1;
  }
}

.intro_typingAnimation__9yqI6 p {
  text-align: center;
  white-space: nowrap; /* Ensure the text stays on a single line */
  overflow: hidden; /* Hide the text until it "types" out */
  animation: intro_typing__CUHUr 1s steps(30, end); /* Adjust timing and steps as needed */
}

.intro_typingAnimation__9yqI6 p:nth-child(2) {
  text-align: center;
  white-space: nowrap; /* Ensure the text stays on a single line */
  overflow: hidden; /* Hide the text until it "types" out */
  animation: intro_typing2__uqxqm 1s steps(30, end); /* Adjust timing and steps as needed */

  animation-delay: 0.2s; /* delay for next lines */
  animation-fill-mode: forwards;
  opacity: 0;
}

.intro_typingAnimation__9yqI6 p:nth-child(3) {
  text-align: center;
  white-space: nowrap; /* Ensure the text stays on a single line */
  overflow: hidden; /* Hide the text until it "types" out */
  animation: intro_typing3__T1Etn 1s steps(30, end); /* Adjust timing and steps as needed */

  animation-delay: 0.4s; /* delay for next lines */
  animation-fill-mode: forwards;
  opacity: 0;
}

.intro_container__o4FdW {
  position: relative;
  overflow: hidden;
}
/* 
.fadeOut,
.fadeIn {
  transition: opacity 1s ease-out, visibility 1s ease-out;
  opacity: 1;
  visibility: visible;
}

.fadeOut {
  opacity: 0;
  visibility: hidden;
}

.fadeIn {
  animation: fadeInAnimation 1s ease-out forwards;
}

@keyframes fadeInAnimation {
  from {
    opacity: 0;
    visibility: hidden;
  }
  to {
    opacity: 1;
    visibility: visible;
  }
} */

